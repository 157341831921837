<template>
  <div class="card">
    <div class="tips">
      <template v-if="detailInfo.assignRecordDTO">
        <div class="resaon">
          <div class="l">
            <i class="iconfont icon-infofill"></i>
          </div>
          <div class="r">
            <span class="title">移交原因：</span
            >{{ detailInfo.assignRecordDTO.transReason | dash }}
          </div>
        </div>
        <div class="resaon">
          <div class="l">
            <i class="iconfont icon-infofill"></i>
          </div>
          <div class="r">
            <span class="title">家访目的：</span
            >{{ detailInfo.assignRecordDTO.homeReason | dash }}
          </div>
        </div>
      </template>
    </div>
    <div class="expireTime">到期时间：{{ detailInfo.expireTime | dateFormat('YYYY-MM-DD') }}</div>
    <CardCustomer :data="detailInfo.homeBasicDetailDTO"></CardCustomer>
    <div class="product">
      <div class="row">
        业务编号：{{ detailInfo.homeBasicDetailDTO.applyBizNo }}
      </div>
      <div class="row">
        商户名：{{ detailInfo.homeBasicDetailDTO.merchantName }}
      </div>
      <div class="row">
        <span>产品名称：{{ detailInfo.homeBasicDetailDTO.productName }}</span>
        <span
          >逾期天数：{{
            detailInfo.homeBasicDetailDTO.currentOverdueDays
          }}天</span
        >
      </div>

      <div class="row">
        <span
          >每期金额：{{
            detailInfo.homeBasicDetailDTO.perAmount | amount
          }}</span
        >
        <span
          >逾期金额：{{
            detailInfo.homeBasicDetailDTO.currentOverdueAmount | amount
          }}</span
        >
      </div>
      <div class="row">
        <span
          >融资金额：{{
            detailInfo.homeBasicDetailDTO.financingAmount | amount
          }}</span
        >
        <span
          >应收余额：{{
            detailInfo.homeBasicDetailDTO.receivableAmount | amount
          }}</span
        >
      </div>
    </div>
    <div class="car">
      <div class="row">
        车牌号：{{ detailInfo.homeBasicDetailDTO.vehiclePlateNo | dash }}
      </div>
      <div class="row">
        车辆型号：{{
          detailInfo.homeBasicDetailDTO.vehicleModelFullName | dash
        }}
      </div>
      <div class="row">
        GPS状态：{{ detailInfo.homeBasicDetailDTO.gpsStatus | dash }}
      </div>
    </div>

    <div class="table" v-if="detailInfo.contactsResponseVOS">
      <div class="row title">
        <span>联系人姓名</span><span>联系人手机号</span><span>关系</span>
      </div>
      <div
        class="row"
        v-for="(contact, index) in detailInfo.contactsResponseVOS"
        :key="index"
      >
        <span>{{ contact.contactsName }}</span
        ><span
          ><a :href="`tel://${contact.contactsPhone}`">{{
            contact.contactsPhone
          }}</a></span
        ><span>{{ contact.relationStr | dash }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detailInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    var fileList = [];
    const { detailInfo } = this;
    if (detailInfo.assignRecordDTO && detailInfo.assignRecordDTO.otherUrls) {
      fileList = detailInfo.assignRecordDTO.otherUrls.map((url) => {
        return { url };
      });
    }
    return {
      fileList,
    };
  },

  methods: {},
};
</script>
<style lang="less" scoped>
.card {
  padding: 0px 0px 20px 0px;
  .tips {
    padding: 5px;

    .resaon {
      background: #ffeace;
      border-radius: 5px;
      margin-bottom: 5px;
      color: #333333;
      font-size: 13px;
      line-height: 1.2em;
      padding: 17px 16px 17px 10px;
      display: flex;
      .l {
        padding-right: 10px;
        i {
          color: #dd8b64;
          font-size: 20px;
        }
      }
      .r {
        flex: 1;
      }
      .title {
        font-weight: 650;
      }
    }
  }

  .product {
    margin-top: 10px;
    background: #fff;
    padding: 5px;
    color: #333333;
    font-size: 14px;
    .row {
      display: flex;
      flex-direction: row;
      line-height: 1.5em;
      span {
        flex: 1;
      }
    }
  }

  .car {
    margin-top: 10px;
    background: #fff;
    padding: 5px;
    color: #333333;
    font-size: 14px;
    .row {
      display: flex;
      flex-direction: row;
      line-height: 1.5em;
      span {
        flex: 1;
      }
    }
  }

  .section {
    margin: 10px 5px 0px 5px;
    background: #fff;
    padding: 8px 10px;
    color: #333333;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 650;
    }
    .action {
      font-size: 12px;
      cursor: pointer;
      color: #02a7f0;
    }
  }

  .expireTime {
    background: #fff;
    margin: 0px 0px 6px 0px;
    padding: 10px 5px;
  }

  .pictures {
    padding: 10px 10px;
  }

  .table {
    margin-top: 10px;
    box-sizing: border-box;
    border: 1px solid #eaecf2;
    border-radius: 2px;

    .row {
      position: relative;
      background: #fff;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 15px 8px;

      &.title {
        font-weight: 650;
      }

      span {
        flex: 1;
        text-align: center;
        // &:first-child {
        //   text-align: left;
        // }
        // &:last-child {
        //   text-align: right;
        // }

        a {
          font-size: 12px;
          color: #3c86ff;
          letter-spacing: 0;
          text-align: justify;
          text-decoration: underline;
        }
      }

      &::after {
        content: " ";
        position: absolute;
        bottom: 0px;
        height: 1px;
        left: 0;
        right: 0;
        background: #eaecf2;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>